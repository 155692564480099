<template>
  <div v-if="loaded">
    <div id="titolo"><h1>Algoritmo</h1></div>
    <div id="classifica">
      <classifica :classifica="squadre" :nomeclassifica='"Algoritmo"' :getpunti="getAlgoritmo" />
      <classifica :classifica="normale" :nomeclassifica='"Classifica"' :getpunti="getNormale" />
    </div>
  </div>
  <div v-else>
    <h1>Caricamento in Corso</h1>
    <img alt="Caricamento" src="https://upload.wikimedia.org/wikipedia/commons/0/04/FabioCaressa.png" />
  </div>
</template>

<script>

import Axios from 'axios';
import Classifica from '../components/Classifica.vue';


export default {
  name: 'Home',
  created () {
    this.getSquadre();
  },
  components: {
    Classifica
  },
  data: () => ({
    squadre: [],
    normale: [],
    secret: "password", 
    loaded: false
  }),
  methods: {
    async getSquadre() {
      let res = await Axios.get("http://algoritmo.carminezacc.com:3000/classifica");
      this.squadre = res.data;
      this.normale = [...this.squadre];
      this.normale.sort((a, b) => b.puntiSA-a.puntiSA);
      this.loaded = true;

    },
    async aggiorna() {
      this.loaded=false;
      this.getSquadre();

    },
    getNormale(el) {return el.puntiSA},
    getAlgoritmo(el) {return (el.punti+el.puntiSA).toFixed(1)}
  }
}

</script>

<style>

#classifica {
  background-color: #E2E2E2;
}

body {
  background-color: #003b8e;
  font-family: 'Lato', sans-serif;
}

#titolo {
  background-color: #1565c0;
  color: white;
  height: 50px;
  margin-top: 0px;
  padding-top: 0px;
}

</style>

<template>
   <table class="center" style="display: inline-block;">
      <thead>
          <th>Nome Squadra</th>
          <th>{{ nomeclassifica }}</th>
      </thead>
      <tr v-for="squadra in classifica" :key="squadra.nome">
        <td><a :href="'/squadra/'+squadra.nome">{{ squadra.nome }} </a></td>
        <td>{{ getpunti(squadra) }} </td>
      </tr>
    </table>
</template>

<script>
//import SquadraInClassifica from './SquadraInClassifica.vue';
export default {
  //components: { SquadraInClassifica },
  name: 'Classifica',
  props: {
    classifica: Array,
    nomeclassifica: String,
    getpunti: Function
  }
}
</script>

<style scoped>

.center {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
}

.center td, #center th {
  border: 1px solid #ddd;
  padding: 6px;
}

.center tr:nth-child(even){
  background-color: #f1f0f0;
}

.center tr:nth-child(odd){
  background-color: white;
}



table {
  padding-bottom: 30px;
}

.center {
  margin: auto;
  width: 280px;
}


a {
  color: black;
  text-decoration: none;
} 
</style>
